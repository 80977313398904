import {sub} from "date-fns";

import {appendQueryString, getExternalRequest} from "@pg-mono/request";
import {apiBigDataLink} from "@pg-mono/rp-api-routes";
import {dateTimeFormat, formatDate} from "@pg-mono/string-utils";

import {bigDataApiUrl} from "../../app/read_rp_environment_variables";
import {cityWithEstimatedPriceStats, districtsWithEstimatedPriceStats} from "../../property/utils/estimated_price_utils";
import {RegionType} from "../types/RegionType";

export interface ISelectedRegionEstimatedPrice {
    date: string;
    slug_city: string;
    city: string;
    avg_price_m2: number;
    avg_price_m2_house: number;
}

export interface IFetchPropertyEstimatedPriceRequestParams {
    region_id?: string;
    slug_district?: string;
    slug_city?: string;
    date_start: string;
    date_end: string;
    scenario: "rp-price-avg";
}

export interface IRegionParam {
    id: number;
    type: RegionType;
}

export const getRegionQueryParams = (region: IRegionParam): Partial<IFetchPropertyEstimatedPriceRequestParams> | null => {
    switch (region.type) {
        case RegionType.DISTRICT:
            const districtRecord = districtsWithEstimatedPriceStats.get(region.id) ?? null;

            return districtRecord
                ? {
                      slug_city: districtRecord.city,
                      slug_district: districtRecord.district
                  }
                : null;

        case RegionType.CITY:
            const slugCity = cityWithEstimatedPriceStats.get(region.id) ?? null;
            return slugCity
                ? {
                      slug_city: slugCity
                  }
                : null;

        default:
            return null;
    }
};

export const fetchRegionEstimatedPrice = (region: IRegionParam): Promise<ISelectedRegionEstimatedPrice[]> => {
    const regionParams = getRegionQueryParams(region);

    if (!regionParams) {
        return Promise.resolve([]);
    }

    const fetchWithDistrict = region.type === RegionType.DISTRICT;

    // Statistics DB hasn't full data for current month, so we need to subtract one month from current date.
    const endDate = sub(new Date(), {months: 1});

    const query: IFetchPropertyEstimatedPriceRequestParams = {
        ...regionParams,
        date_start: formatDate(sub(endDate, {months: 12}).toISOString(), dateTimeFormat.dashedDateFormat),
        date_end: formatDate(endDate.toISOString(), dateTimeFormat.dashedDateFormat),
        scenario: "rp-price-avg"
    };

    const endpointLink = fetchWithDistrict ? apiBigDataLink.monthDistrictsStats.base : apiBigDataLink.monthCityStats.base;
    const url = appendQueryString(endpointLink(bigDataApiUrl), {...query});

    return getExternalRequest({}, url, 5000);
};
