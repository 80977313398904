import React, {useRef, useState} from "react";
import {getTrackBackground, Range as ReactRange} from "react-range";
import {css, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {flexAbsoluteCenter, pb, pt, w100} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {IRange, RangeType} from "../types";
import {getInitialValue} from "../utils/get_initial_values";
import {shortenBigNumbers} from "../utils/shorten_big_numbers";

interface IProps {
    name: string;
    value: IRange<RangeType>;
    error?: string[];
    onChange: (fieldName: string, value: IRange<RangeType>) => void;
    step: number;
    min: number;
    max: number;
}

const getLabelVisibility = (values: number[], min: number, max: number) => {
    const diff = max - min;
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);
    const factor = 0.15;

    return {
        showLeftLabel: minValue > min + diff * factor,
        showRightLabel: maxValue < max - diff * factor
    };
};

export const Range = (props: IProps) => {
    const {min, max, step} = props;
    const theme = useTheme();
    const rangeRef = useRef<Range>(null);
    const [localValues, setLocalValues] = useState(getInitialValue(props.value, props.min));

    const {showLeftLabel, showRightLabel} = getLabelVisibility(localValues, min, max);

    return (
        <div css={[pt(2)]}>
            <div css={[flexAbsoluteCenter, w100, pb(1)]}>
                <Text variant="body_copy_1" color={theme.colors.gray[700]} align="left" css={[w100, showLeftLabel ? "" : visibilityHidden]}>
                    {shortenBigNumbers(props.min)}
                </Text>
                <Text variant="body_copy_1" color={theme.colors.gray[700]} align="right" css={[w100, showRightLabel ? "" : visibilityHidden]}>
                    {shortenBigNumbers(props.max)}
                </Text>
            </div>
            <ReactRange
                ref={rangeRef as never}
                onFinalChange={(newValues) => {
                    props.onChange(props.name, {
                        lower: 0,
                        upper: newValues[0]
                    });
                }}
                onChange={setLocalValues}
                values={localValues}
                step={step}
                min={min}
                max={max}
                renderTrack={({props, children}) => (
                    <div onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart} css={[{...props.style}, trackWrapper]}>
                        <TrackContainer ref={props.ref} values={localValues} min={min} max={max}>
                            {children}
                        </TrackContainer>
                    </div>
                )}
                renderThumb={({props}) => (
                    <div {...props} css={[{...props.style}, thumbStyle]}>
                        <div css={labelStyle}>{shortenBigNumbers(props["aria-valuenow"])}</div>
                    </div>
                )}
            />
        </div>
    );
};

const trackWrapper = css`
    width: calc(100% - 3.2rem);
    margin-left: 1.6rem;
    flex-grow: 1;
    display: flex;
`;

const thumbStyle = (theme: Theme) => css`
    height: 3.2rem;
    width: 3.2rem;
    background-color: ${theme.colors.primary};
    border: 1px solid #23232d;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    outline: none;

    &:after {
        display: block;
        content: "";
        position: absolute;
        border-left: 1px solid ${theme.colors.secondary};
        height: 2rem;
        bottom: 100%;
        left: calc(50% - 1px);
    }
`;

const labelStyle = css`
    position: absolute;
    white-space: nowrap;
    bottom: calc(100% + 2rem);
`;

const TrackContainer = styled.div<{
    values: number[];
    min: number;
    max: number;
}>`
    width: 100%;
    height: 18px;
    border-radius: 100px;
    background-color: ${({theme}) => theme.colors.background[200]};
    align-self: center;
    background: ${({values, theme, min, max}) =>
        getTrackBackground({
            values,
            colors: [theme.colors.primary, "#E1D7CD"],
            min,
            max
        })};
`;

const visibilityHidden = css`
    visibility: hidden;
`;
